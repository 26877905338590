import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './App';

const fetchJsFromCDN = (src, externals = []) => {
    new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.setAttribute('src', src)
        script.addEventListener('load', () => {
            resolve(externals.map(key => {
                const ext = window[key]
                typeof ext === 'undefined' && console.warn(`No external named '${key}' in window`)
                return ext
            }))
        })
        script.addEventListener('error', reject)
        document.body.appendChild(script)
    })
}
fetchJsFromCDN('https://cdnjs.cloudflare.com/ajax/libs/flickity/1.0.0/flickity.pkgd.js', []);
fetchJsFromCDN('https://yastatic.net/es5-shims/0.0.2/es5-shims.min.js', []);
fetchJsFromCDN('https://yastatic.net/share2/share.js', []);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

import React from "react";
import {Encyclopedia} from './components/encyclopedia';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

class App extends React.Component {

  constructor(props) {
    super(props);

    this._handlerRedirect = this._handlerRedirect.bind(this);
  }

  _handlerRedirect(event, action) {
      event.preventDefault();

      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);

      window.location = 'http://matbea.com/?utm_source=' + params.get('utm_source') + '&action=' + action;
  }

  render() {
    return (
      <div>
          <div>
              <YMInitializer accounts={[86624077]} />
          </div>
          <div className="header">
              <div className="header_information">
                  <div className="logo" />
                  <div className="login">
                      <a href="http://matbea.com" className="btn" onClick={(event) => {
                          ym('reachGoal','button-enter');
                          this._handlerRedirect(event, 'login');
                      }}>Войти</a>
                  </div>
              </div>
          </div>
        <Encyclopedia
            _handlerRedirect={this._handlerRedirect}
        />
      </div>
    );
  }
}

export default App;

import "./encyclopedia.scss";
import React, { Component } from "react";
import BankIcon from "./assets/bank.png";
import BookIcon from "./assets/book.png";
import BTCIcon from "./assets/btc.png";
import PrognozIcon from "./assets/prognoz.png";
import QuestionIcon from "./assets/question.png";
import { ReactComponent as FbIcon } from "./assets/fb.svg";
import { ReactComponent as OkIcon } from "./assets/ok.svg";
import { ReactComponent as TgIcon } from "./assets/tg.svg";
import { ReactComponent as VkIcon } from "./assets/vk.svg";
import ym from 'react-yandex-metrika';
import $ from 'jquery';

class EncyclopediaComponent extends Component {

  componentDidMount() {
    setTimeout(() => {
      $('.ya-share2__link').on('click', function (e) {
        if ($(this).attr('title')) {
          let goal = null;
          switch($(this).attr('title')) {
            case 'Telegram':
              goal = 'telegram';
              break;
            case 'ВКонтакте':
              goal = 'vk';
              break;
            case 'Facebook':
              goal = 'facebook';
              break;
            case 'Одноклассники':
              goal = 'ok';
              break;
            default:
              goal = null;
          }

          if (goal) {
            ym('reachGoal', goal);
          }
        }
      });
    }, 500);

  }

  renderCards() {
    return [
      {
        title: "Зачем нужен биткоин",
        link: "https://",
        icon: QuestionIcon,
      },
      {
        title: (<div>
          <div>
            Где взять
          </div>
          <div>
            биткоин бесплатно
          </div>
        </div>),
        link: "https://",
        icon: BTCIcon,
      },
      {
        title: (<div>
          <div>
            Как найти
          </div>
          <div>
            и как хранить
          </div>
        </div>),
        link: "https://",
        icon: BankIcon,
      },
      {
        title: "Прогнозы и " +
          "советы",
        link: "https://",
        icon: PrognozIcon,
      },
    ].map((item, key) => {
      return (
        <div
          key={`cardKey${key}`}
          className="encyclopedia_card"
          style={{ backgroundImage: `url(${item.icon})` }}
        >
          <span>{item.title}</span>
        </div>
      );
    })
  };

  renderIcons() {
    return [
      {
        alt: "Telegram",
        link: "https://",
        icon: <TgIcon/>,
      },
      {
        alt: "Vk",
        link: "https://",
        icon: <VkIcon/>,
      },
      {
        alt: "Fb",
        link: "https://",
        icon: <FbIcon/>,
      },
      {
        alt: "Ok",
        link: "https://",
        icon: <OkIcon/>,
      },
    ].map((item, key) => {
      return (
        <div className="encyclopedia_present_icon" key={`iconKey${key}`}>
          {item.icon}
        </div>
      );
    })
  };

  render() {
    return (
      <div className="encyclopedia">
        <div className="encyclopedia_background-gray">
          <div className="encyclopedia_column">
            <div className="encyclopedia_divider_100"/>
            <div className="encyclopedia_title">
              <span>
                Вы начинающий криптовинвестор? Хотите заниматься майнингом?<br/>
                Устали от обилия разнородной информации и хотите быстро разобраться?
              </span>
            </div>
            <div className="encyclopedia_divider_50"/>
            <div className="encyclopedia_present">
              <div className="encyclopedia_present_layout" style={{ backgroundImage: `url(${BookIcon})` }}>
                <div className="encyclopedia_present_content">
                  <div className="encyclopedia_present_content_subtitle">
                    <span>Представляем</span>
                  </div>
                  <div className="encyclopedia_present_content_title">
                    <h1>Энциклопедия<br/>Криптоинвестора</h1>
                  </div>
                  <div className="encyclopedia_present_content_footer">
                    <a href="https://s3.eu-central-1.amazonaws.com/hosting.images/matbea/LearnMatbea.pdf " target="_blank" rel="noreferrer"
                       onClick={(event) => {
                         ym('reachGoal','button-download1')
                       }}>
                      <div className="encyclopedia_present_content_footer_button">
                          <span style={{textAlign: 'center'}}>
                            ОТКРЫТЬ ЭНЦИКЛОПЕДИЮ
                          </span>
                      </div>
                    </a>
                    <div style={{ color: "#fff", alignSelf: "center" }}>Поделиться:</div>
                    <div className="encyclopedia_present_content_footer_icons">
                      <div className="encyclopedia_present_social-networks">
                        <div className="ya-share2 share-page" data-services="telegram,vkontakte,facebook,odnoklassniki"
                             data-title="Энциклопедия Криптоинвестора"
                             data-description="Вы начинающий криптовинвестор? Хотите заниматься майнингом? Устали от обилия разнородной информации и хотите быстро разобраться?"
                             data-image={window.location.protocol + '//' + window.location.hostname + "/static/media/book.21e2f99f.png"}
                        />
                      </div>
                      {/*{this.renderIcons()}*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="encyclopedia_background-gray">
          <div className="encyclopedia_column">
            <div className="encyclopedia_divider_100"/>
            <div className="encyclopedia_title">
              <span>
                Рассмотрены базовые вопросы
              </span>
            </div>
            <div className="encyclopedia_divider_50"/>
            <div className="encyclopedia_cards_wrapper">
              {this.renderCards()}
            </div>
            <div className="encyclopedia_divider_50"/>
            <div className="encyclopedia_subheading">
              <span>
                Материал изложен кратко и с большим количеством картинок, даны ссылки на более
                подробные статьи  для углубленного знакомства с интересующей темой
              </span>
            </div>
            <div className="encyclopedia_divider_30"/>
            <div className="encyclopedia_subheading">
              <a className="encyclopedia_button-link" href="https://s3.eu-central-1.amazonaws.com/hosting.images/matbea/LearnMatbea.pdf" target="_blank" rel="noreferrer"
                 onClick={(event) => {
                     ym('reachGoal','button-download2')
                 }}>
                <div className="encyclopedia_present_content_button-download" style={{width: '230px'}}>
                    <span>
                      ОТКРЫТЬ ЭНЦИКЛОПЕДИЮ
                    </span>
                </div>
              </a>
            </div>

            <div className="encyclopedia_divider_100"/>
          </div>
        </div>

        <div className="encyclopedia_background-white">
          <div className="encyclopedia_column">
            <div className="encyclopedia_divider_100"/>
            <div className="encyclopedia_title">
              <span>
                MATBEA - Мультивалютный кошелек с функцией обменника
              </span>
            </div>
            <div className="encyclopedia_divider_40"/>
            <div className="encyclopedia_divider_60"/>
            <div className="encyclopedia_benefits_wrapper">
              <div className="encyclopedia_benefit">
                <div className="icon_box">
                  <div className="icon icon_flower" />
                </div>
                <div className="text">
                  Выгодные условия пополнения
                </div>
              </div>
              <div className="encyclopedia_benefit">
                <div className="icon_box">
                  <div className="icon icon_rocket" />
                </div>
                <div className="text">
                  Быстрая конвертация внутри кошелька
                </div>
              </div>
              <div className="encyclopedia_benefit">
                <div className="icon_box">
                  <div className="icon icon_0_percent" />
                </div>
                <div className="text">
                  Беспроцентные переводы внутри MATBEA
                </div>
              </div>
              <div className="encyclopedia_benefit">
                <div className="icon_box">
                  <div className="icon icon_shield" />
                </div>
                <div className="text">
                  Стабильно работаем
                  с 2014 года
                </div>
              </div>
            </div>
            <div className="encyclopedia_divider_120"/>
            <div className="encyclopedia_title">
              <span style={{fontWeight: 'normal'}}>
                Смотрите отзывы о нас на популярных сервисах
              </span>
            </div>
            <div className="encyclopedia_divider_50"/>


            <div className="gallery js-flickity"
                 data-flickity-options='{ "wrapAround": true }'>
              <a href="https://forum.bits.media/index.php?/topic/8690-matbi-kupitprodat-bitkoin-za-qiwi-matbi-otzyvy/">
                <div className="gallery-cell">
                  <span className="ico ico-bitcoin-security lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="http://mmgp.ru/showthread.php?t=311646">
                <div className="gallery-cell">
                  <span className="ico ico-mmgp lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="http://www.tula.aif.ru/money/finance/1150711">
                <div className="gallery-cell">
                  <span className="ico ico-aif lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://youtu.be/BuJJYEBkEPA">
                <div className="gallery-cell">
                  <span className="ico ico-cryptum-tv lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://habr.com/ru/post/417299/">
                <div className="gallery-cell">
                  <span className="ico ico-habr lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://vc.ru/42563-kak-kupit-bitkoin-v-rossii-v-2018-godu">
                <div className="gallery-cell">
                  <span className="ico ico-vc-ru lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://probitcoin.ru/2018/06/29/kak-kupit-bitkoin-i-drugie-kriptovalyuty-bezopasno-i-vygodno/">
                <div className="gallery-cell">
                  <span className="ico ico-pro-bitcoin lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://brodude.ru/h-oshibok-pri-pokupke-bitkoinov-kotorye-dopuskayut-vse/">
              <div className="gallery-cell">
                <span className="ico ico-brodude-logo-black lazy" data-loader="responsiveCSSBgLoader" />
              </div>
              </a>
              <a href="http://www.coinfox.ru/novosti/obzory/10649-matbi">
                <div className="gallery-cell">
                  <span className="ico-coinfox-box">
                      <span className="ico ico-coinfox lazy" data-loader="responsiveCSSBgLoader" />
                  </span>
                </div>
              </a>
              <a href="https://ruscoins.info/articles/luchshie-koshelki-dlya-kriptovalyutyi/#_5_Matbea_com">
                <div className="gallery-cell">
                  <span className="ico ico-ruscoins lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://mining-cryptocurrency.ru/vybor-koshelka-dlya-kriptovalyuty/">
                <div className="gallery-cell">
                  <span className="ico ico-cryptomine lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://altcoinlog.com/top-reiting-koshelkov-kriptovalit/#i-5">
                <div className="gallery-cell">
                  <span className="ico ico-altcoin-log lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://bytwork.com/currencies/best">
                <div className="gallery-cell">
                  <span className="ico ico-btw lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://profinvestment.com/cryptocurrency-wallet/">
                <div className="gallery-cell">
                  <span className="ico ico-profinvestment lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
              <a href="https://maff.io/kak_prodat_bitcoin/">
                <div className="gallery-cell">
                  <span className="ico ico-maff lazy" data-loader="responsiveCSSBgLoader" />
                </div>
              </a>
            </div>
            <div className="encyclopedia_divider_100"/>
            <div className="encyclopedia_subheading">
              <span style={{ fontSize: '25px' }}>
                Зарегистрируйтесь сейчас и получите 200 бонусов, <br/>которые сможете потратить для покупки криптовалюты
              </span>
            </div>
            <div className="encyclopedia_divider_60" />
            <div className="encyclopedia_subheading">
              <a href="http://matbea.com" className="btn_registration" onClick={(event) => {
                ym('reachGoal','button-registration');
                this.props._handlerRedirect(event, 'register');
              }}>Регистрация</a>
            </div>
            <div className="encyclopedia_divider_100" />
          </div>
        </div>
      </div>
    );
  }
}

export const Encyclopedia = React.memo(EncyclopediaComponent);
